import React from 'react'
import {Grid} from '@mui/material'
import Invoice, {
  getDiscount,
  getGlobalRate,
  getSubTotalAmount,
  getTotalAmendmenetsCharged,
  getTotalAmount
} from 'src/entities/Invoice'
import {BillingInfo} from 'src/entities/BillingInfo'
import {formatNumber} from 'src/utils/numberHelper'

interface InvoiceDetailsTotalProps {
  invoice: Invoice
  billingInfo: BillingInfo
  invoicesAmount: number
}

export default function InvoiceDetailsTotal({
  invoice,
  billingInfo,
  invoicesAmount
}: InvoiceDetailsTotalProps) {
  return (
    <Grid container justifyContent="flex-end">
      <Grid container item xs={5} spacing={2}>
        <Grid item xs={6} textAlign="right">
          Total Paid Amendments:
        </Grid>
        <Grid item xs={6}>
          {getTotalAmendmenetsCharged(invoice.taskTypes, billingInfo)}
        </Grid>
        <Grid item xs={6} textAlign="right">
          Subtotal:
        </Grid>
        <Grid item xs={6}>
          {formatNumber(
            invoice.id
              ? invoice?.subAmount
              : getSubTotalAmount(invoice?.taskTypes)
          )}
        </Grid>
        <Grid item xs={6} textAlign="right">
          Global Rate:
        </Grid>
        <Grid item xs={6}>
          {formatNumber(getGlobalRate(billingInfo?.globalRate, invoicesAmount))}
        </Grid>
        <Grid item xs={6} textAlign="right">
          Discount
        </Grid>
        <Grid item xs={6}>
          {formatNumber(
            invoice.id
              ? invoice.billingInfo.discount
              : getDiscount(billingInfo?.discount, invoicesAmount)
          )}
        </Grid>
        <Grid item xs={6} textAlign="right">
          Discount Percent
        </Grid>
        <Grid item xs={6}>
          {(invoice.id
            ? invoice.billingInfo.discountPercent
            : invoice?.billingInfo?.discountPercent || 0) + '%'}
        </Grid>
        <Grid item xs={6} textAlign="right">
          Total
        </Grid>
        <Grid item xs={6}>
          {formatNumber(
            invoice.id
              ? invoice.amount
              : getTotalAmount(invoice?.taskTypes, billingInfo, invoicesAmount)
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

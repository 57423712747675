import InvoiceAttachment from 'src/entities/InvoiceAttachment'
import {
  BillingTaskType,
  BillingInfo,
  BillingTask,
  SplitInvoiceBy,
  SplitInvoiceByMapping,
  getTaskAmount,
  getAmendmenetsCharged
} from 'src/entities/BillingInfo'
import Person from 'src/entities/Person'
import ProjectBilling, {BaseProjectBilling} from './ProjectBilling'
import {round} from 'src/utils/numberHelper'
import {ProjectStatus} from './Project'
import {BaseResource} from './BaseResource'

export interface BaseInvoiceResource extends BaseResource {
  invoiceNumber: string
}

export default interface Invoice extends BaseInvoiceResource {
  projectId: number
  project: BaseProjectBilling
  billingInfo: BillingInfo
  subAmount: number
  amount: number
  paidAmount?: number
  paidAt?: Date
  comments: string
  salesReps: Person[]
  attachments: InvoiceAttachment[]
  taskTypes: BillingTaskType[]
  isDeleted: boolean
  splitByValue: string
  discount: number
  discountPercent: number
  projectStatus: ProjectStatus
  isProjectStatusChanged: boolean
  archiveComments: string
}

export const projectStatusCompleted: number[] = [
  ProjectStatus.InProgress,
  ProjectStatus.InvoiceCreated,
  ProjectStatus.InvoiceSent,
  ProjectStatus.Paid,
  ProjectStatus.PartiallyPaid
]

export const getGlobalRate = (globalRate: number, invoicesAmount: number) => {
  return round(globalRate / invoicesAmount) || 0
}

export const getDiscount = (discount: number, invoicesAmount: number) => {
  return round(discount / invoicesAmount) || 0
}

export const getSubTotalAmount = (
  taskTypes: BillingTaskType[],
  chargeAmendmentsFrom = 0,
  amendmentRate = 0
) => {
  let total = 0
  taskTypes?.map((taskType: BillingTaskType) =>
    taskType?.tasks?.map(
      (task: BillingTask) =>
        (total += task.type?.isGeneralProjectTimes
          ? 0
          : task.amount ||
            getTaskAmount(taskType, task, chargeAmendmentsFrom, amendmentRate))
    )
  )
  return round(total)
}

export const getTotalAmount = (
  taskTypes: BillingTaskType[] = [],
  billingInfo: BillingInfo,
  invoicesAmount: number,
  subTotal?: number
) => {
  return (
    round(
      ((subTotal || getSubTotalAmount(taskTypes)) +
        getGlobalRate(billingInfo?.globalRate, invoicesAmount) -
        getDiscount(billingInfo?.discount, invoicesAmount)) *
        ((100 - billingInfo?.discountPercent) / 100 || 1)
    ) || 0
  )
}

export const getTotalTypeAmendmenetsCharged = (
  tasks: BillingTask[] = [],
  billingInfo: BillingInfo
) => {
  return tasks.reduce(
    (sum, task) =>
      sum + getAmendmenetsCharged(task, billingInfo?.chargeAmendmentsFrom),
    0
  )
}

export const getTotalAmendmenetsCharged = (
  taskTypes: BillingTaskType[] = [],
  billingInfo: BillingInfo
) => {
  return taskTypes.reduce(
    (sum, taskType) =>
      sum + getTotalTypeAmendmenetsCharged(taskType.tasks, billingInfo),
    0
  )
}

export const getAllInvoicesAmount = (invoices: Invoice[]) => {
  return invoices.reduce(
    (sum, invoice) =>
      sum +
      (invoice.id
        ? invoice.amount
        : getTotalAmount(
            invoice?.taskTypes,
            invoice.billingInfo,
            invoices.length
          )),
    0
  )
}

export const splitInvoices = (
  data: BillingTaskType[],
  splitBy: SplitInvoiceBy
) => {
  return data.reduce(function (
    storage: Record<string, Record<string, BillingTaskType>>,
    taskType: BillingTaskType
  ) {
    let invoiceGroup: string
    let taskTypeGroup: string
    taskType.tasks.forEach((task: BillingTask) => {
      invoiceGroup =
        task[SplitInvoiceByMapping[splitBy] as keyof BillingTask]?.toString() ||
        ''
      taskTypeGroup = taskType.typeName || ''
      storage[invoiceGroup] = storage[invoiceGroup] || []
      storage[invoiceGroup][taskTypeGroup] =
        storage[invoiceGroup][taskTypeGroup] || {}
      if (Object.keys(storage[invoiceGroup][taskTypeGroup]).length === 0) {
        storage[invoiceGroup][taskTypeGroup] = {...taskType} as BillingTaskType
        storage[invoiceGroup][taskTypeGroup].tasks = []
      }
      storage[invoiceGroup][taskTypeGroup].tasks =
        storage[invoiceGroup][taskTypeGroup].tasks || []
      storage[invoiceGroup][taskTypeGroup].tasks.push(task as BillingTask)
    })

    return storage
  },
  {})
}

export const createInvoices = (
  invoicesArray: Record<
    string,
    Record<string, BillingTaskType | BillingTaskType>
  >,
  projectBilling: ProjectBilling
) => {
  let invoices: Invoice[] = []

  invoicesArray &&
    Object.entries(invoicesArray).map(
      ([key, invoice]: [string, Record<string, BillingTaskType>]) => {
        let invoiceTaskTypes: BillingTaskType[] = []
        invoice &&
          Object.values(invoice).map((billingTaskType: BillingTaskType) => {
            invoiceTaskTypes.push(billingTaskType)
            return billingTaskType
          })

        invoices.push({
          billingInfo: projectBilling?.billingInfo,
          projectId: projectBilling?.id,
          taskTypes: invoiceTaskTypes?.map((taskType: BillingTaskType) => {
            return {
              ...taskType,
              tasks: taskType?.tasks?.map((task: BillingTask) => {
                return {
                  ...task,
                  amount: getTaskAmount(
                    taskType,
                    task,
                    projectBilling?.billingInfo?.chargeAmendmentsFrom,
                    projectBilling?.billingInfo?.amendmentRate
                  )
                }
              })
            }
          }),
          splitByValue:
            projectBilling.billingInfo.splitInvoiceBy !== SplitInvoiceBy.None
              ? key
              : projectBilling.name
        } as Invoice)
        return invoice
      }
    )
  return invoices.sort((a, b) =>
    a.splitByValue?.toUpperCase() > b.splitByValue?.toUpperCase() ? 1 : -1
  )
}

export const getNewInvoices = (
  projectBilling: ProjectBilling,
  invoices: any[]
) => {
  return invoices.map((invoice: Invoice) => {
    const taskTypesCalculated = invoice?.taskTypes?.map(
      (taskType: BillingTaskType) => {
        return {
          ...taskType,
          tasks: taskType?.tasks?.map((task: BillingTask) => {
            return {
              ...task,
              amount: getTaskAmount(
                taskType,
                task,
                projectBilling?.billingInfo.chargeAmendmentsFrom,
                projectBilling?.billingInfo.amendmentRate
              )
            }
          })
        }
      }
    )
    return {
      ...invoice,
      billingInfo: {
        ...invoice.billingInfo,
        globalRate: getGlobalRate(
          projectBilling?.billingInfo?.globalRate,
          invoices.length
        )
      },
      discountPercent: projectBilling?.billingInfo?.discountPercent,
      discount: getDiscount(
        projectBilling?.billingInfo?.discount,
        invoices.length
      ),
      subAmount: getSubTotalAmount(taskTypesCalculated),
      amount: getTotalAmount(
        taskTypesCalculated,
        projectBilling?.billingInfo,
        invoices.length
      ),
      taskTypes: taskTypesCalculated
    }
  })
}

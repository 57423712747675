import {
  ScopeSection,
  DataPoint,
  DataPointType,
  DataPointSpecialType
} from './Scope'
import BaseWithNameResource, {BaseResource} from 'src/entities/BaseResource'
import {getFullFormattedDate, validateDate} from 'src/utils/formatDate'

export interface ItemDataPoint extends BaseResource {
  item: BaseResource
  dataPoint: DataPoint
  dataPointId: number
  dataPointName: string
  value: string
  suggestedValue: string
  highlightAnnotationCount: number
  newValue: string
  status: DataPointValueStatus
  isUpdating: boolean
  isSavedSucceeded: boolean
  isSavedFailed: boolean
}
export interface ScopeSectionItem {
  scopeSection: ScopeSection
  itemDataPoints: ItemDataPoint[]
}
export interface SectionItemDataPoint extends BaseWithNameResource {
  itemDataPoints: ItemDataPoint[]
}

export enum DataPointValueStatus {
  None,
  CopyFromDocument,
  CopyFromDocumentWithChanges,
  CopyFromDB,
  ReviewSuggested,
  ChangesApproved
}

export function validateValue(
  data: string | Date,
  dataPointType: DataPointType
) {
  if (dataPointType === DataPointType.Number) {
    const withoutCommaNum = (data as string).replace(/,/g, '')
    return (
      !isNaN(Number(withoutCommaNum)) &&
      /^\d{0,10}(\.\d{0,5})?$/.test(withoutCommaNum) &&
      (!(data as string).includes(',') ||
        withoutCommaNum.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') ===
          data)
    )
  } else if (dataPointType === DataPointType.Date) {
    if (data instanceof Date) {
      const d = new Date(data)
      return !isNaN(d.getTime())
    } else return !data || validateDate(data.toString())
  } else return true
}

export const formatValue = (data: string | Date, type: DataPointType) => {
  switch (type) {
    case DataPointType.Date:
      return getFullFormattedDate(new Date(data))
    case DataPointType.Text:
      return /\S/.test(data as string) ? (data as string) : ''
    case DataPointType.Number:
      return (data as string).replace(/,/g, '')
    default:
      return ''
  }
}

export const isRegularDataPoint = (itemDatapoint: ItemDataPoint) => {
  return itemDatapoint.dataPoint.specialType === DataPointSpecialType.None
}

export function getDataPoints(sections: SectionItemDataPoint[]) {
  return (
    sections?.reduce(
      (prev, current) =>
        prev.concat(current.itemDataPoints.filter(x => isRegularDataPoint(x))),
      [] as ItemDataPoint[]
    ) || ([] as ItemDataPoint[])
  )
}

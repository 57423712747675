import React, {useState} from 'react'
import {
  converStringDateToUtcDate,
  getFullFormattedDate,
  getUtcDateFromLocalDate,
  validateDate
} from 'src/utils/formatDate'

import CuiTextField, {CuiTextProps} from './CuiTextField'
import {TextFieldProps} from '@mui/material'
import {
  DesktopDatePicker,
  DesktopDatePickerProps
} from '@mui/x-date-pickers-pro'

const converToUtcDate = (value: any) => {
  if (typeof value === 'string') {
    const date = new Date(value + 'Z')
    if (!isNaN(date.getTime()) && date.toString() !== value?.toString()) {
      return getUtcDateFromLocalDate(date)
    }
  }
  return value
}

export type CuiDatePickerProps = Omit<
  DesktopDatePickerProps<Date | string, Date>,
  'onChange' | 'renderInput'
> & {
  onChange: (date: Date | null, value: string) => void
  inputTextProps?: CuiTextProps & TextFieldProps
  updateOnBlur?: boolean
}

const maxDate = new Date('01/01/2200')
const minDate = new Date('01/01/1900')

export default function CuiDatePicker({
  onChange,
  value,
  inputTextProps,
  updateOnBlur,
  ...prop
}: CuiDatePickerProps) {
  const [error, setError] = useState<string>()
  const [pickerValue, setPickerValue] = useState<
    | {
        date: any
        keyboardInputValue?: string
      }
    | undefined
  >(undefined)

  const updateValue = (date: any, keyboardInputValue?: string) => {
    if (!keyboardInputValue) {
      const d = converStringDateToUtcDate(getFullFormattedDate(date as Date))
      onChange(d, '')
    } else {
      if (keyboardInputValue.length < 10) {
        onChange(date, keyboardInputValue)
      } else {
        const d2 = converStringDateToUtcDate(keyboardInputValue)
        onChange(d2, keyboardInputValue)
      }
    }
  }

  const onBlur = () => {
    if (!!updateOnBlur && pickerValue)
      updateValue(pickerValue?.date, pickerValue?.keyboardInputValue)
  }

  const onAccept = (date: any) => {
    updateValue(date, '')
  }

  const onChangeDate = (date: any, keyboardInputValue?: string) => {
    if (!!updateOnBlur) {
      setPickerValue({date: date, keyboardInputValue: keyboardInputValue})
    } else updateValue(date, keyboardInputValue)
  }

  const minimimDate = (prop.minDate as Date) || minDate
  const utcValue = converToUtcDate(value)
  return (
    <DesktopDatePicker
      maxDate={maxDate}
      minDate={minimimDate}
      value={utcValue}
      inputFormat="MM/dd/yyyy"
      renderInput={params => (
        <CuiTextField
          onPaste={(e: any) => {
            const a = e.clipboardData.getData('text')
            const b = validateDate(a)
            if (b) {
              const y = converStringDateToUtcDate(a || '')
              const x: string = getFullFormattedDate(y as Date) || ''
              onChangeDate(y, x)
              e.preventDefault()
            }
          }}
          onBlur={onBlur}
          {...params}
          {...inputTextProps}
          error={!!inputTextProps?.error || !!error}
          helperText={error ? error : undefined}
        />
      )}
      onAccept={onAccept}
      onChange={onChangeDate}
      onError={(reason, value) => {
        switch (reason) {
          case 'invalidDate':
            setError('Invalid date format')
            break
          case 'maxDate':
            setError(
              `Date should not be after ${getFullFormattedDate(maxDate)}`
            )
            break
          case 'minDate':
            setError(
              `Date should not be before ${getFullFormattedDate(minimimDate)}`
            )
            break
          default:
            setError('')
        }
        if (inputTextProps?.required && !value) setError('Required')
      }}
      {...prop}
    />
  )
}

import React, {useState, useEffect} from 'react'
import {
  Grid,
  List,
  Dialog,
  DialogContent,
  Divider,
  DialogActions,
  Typography,
  Stack
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  CuiAccordion,
  CuiAccordionDetails,
  CuiAccordionSummary
} from 'src/components/custom/CuiAccordion'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import CuiLoading from 'src/components/custom/CuiLoading'
import InvoiceDetailsTaskFields from 'src/components/billing/invoice/InvoiceDetailsTaskFields'
import InvoicesDetailsFooter from 'src/components/billing/invoice/InvoicesDetailsFooter'
import InvoiceDetailsTotal from 'src/components/billing/invoice/InvoiceDetailsTotal'
import {useAuth} from 'src/context/Auth'
import Invoice, {
  splitInvoices,
  createInvoices,
  getNewInvoices,
  getSubTotalAmount
} from 'src/entities/Invoice'
import ProjectBilling from 'src/entities/ProjectBilling'
import {BillingTaskType, BillingTask} from 'src/entities/BillingInfo'
import config from 'src/config'
import CuiNumberFormat from 'src/components/custom/CuiNumberFormat'

interface InvoicesDetailsModalProps {
  open: boolean
  onClose: () => void
  onSave?: () => void
  onChangeBillingInfo?: (key: string, data: any) => void
  invoiceId?: number | null
  projectBilling?: ProjectBilling
}

export default function InvoicesDetailsModal({
  open,
  onClose,
  onSave,
  onChangeBillingInfo,
  invoiceId,
  projectBilling
}: InvoicesDetailsModalProps) {
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [loading, setLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isSavingFailed, setIsSavingFailed] = useState(false)
  const {fetchWithUser} = useAuth()

  const onSaveClick = () => {
    if (projectBilling) {
      setIsSaving(true)
      setIsSavingFailed(false)
      const newInvoices = getNewInvoices(projectBilling, invoices)

      fetchWithUser(config.apiUrl + '/invoice', {
        method: 'POST',
        body: JSON.stringify(newInvoices),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(() => {
          onSave?.()
          onClose()
        })
        .catch(() => {
          setIsSaving(false)
          setIsSavingFailed(true)
        })
    }
  }

  useEffect(() => {
    invoiceId &&
      fetchWithUser(`${config.apiUrl}/invoice/${invoiceId}`, {
        method: 'GET'
      })
        .then(response => response.json())
        .then((data: Invoice) => {
          setInvoices([data])
        })
        .finally(() => {
          setLoading(false)
        })
  }, [fetchWithUser, invoiceId])

  useEffect(() => {
    if (projectBilling) {
      const invoicesSplit =
        projectBilling?.taskTypes &&
        splitInvoices(
          projectBilling?.taskTypes,
          projectBilling?.billingInfo?.splitInvoiceBy
        )
      setInvoices(createInvoices(invoicesSplit, projectBilling))
      setLoading(false)
    }
  }, [projectBilling])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
      <DialogContent sx={{height: 'calc(90vh)'}}>
        {invoices.length === 1 &&
        invoices[0].taskTypes.flatMap(x => x.tasks).length === 0 ? (
          <Typography p={6} sx={{whiteSpace: 'pre-wrap'}}>
            {invoices[0].archiveComments!}
          </Typography>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {loading && <CuiLoading />}
              {invoices?.map((invoice: Invoice, index: number) => (
                <CuiAccordion
                  key={invoice?.splitByValue}
                  TransitionProps={{unmountOnExit: true}}
                  defaultExpanded={invoices.length === 1}
                  square
                >
                  <CuiAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {invoice.id
                      ? 'Invoice # ' + (invoice.invoiceNumber || '')
                      : (invoices.length > 1
                          ? 'Invoice ' + (index + 1) + ' - '
                          : '') + invoice?.splitByValue}
                  </CuiAccordionSummary>
                  <CuiAccordionDetails>
                    <Grid>
                      {invoice?.taskTypes?.map((taskType: BillingTaskType) => (
                        <CuiAccordion
                          key={taskType?.projectTaskTypeId}
                          defaultExpanded={true}
                          square
                        >
                          <CuiAccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {taskType?.typeName}
                          </CuiAccordionSummary>
                          <CuiAccordionDetails>
                            <List key={'items_' + taskType.projectTaskTypeId}>
                              {taskType?.tasks?.map(
                                (task: BillingTask) =>
                                  taskType &&
                                  (projectBilling || invoice) && (
                                    <InvoiceDetailsTaskFields
                                      key={task.taskId}
                                      billingInfo={
                                        projectBilling?.billingInfo ||
                                        invoice?.billingInfo
                                      }
                                      task={task}
                                      taskType={taskType}
                                    />
                                  )
                              )}
                            </List>
                            <Stack
                              mr={10}
                              justifyContent="flex-end"
                              spacing={2}
                              direction="row"
                            >
                              <Typography>Total:</Typography>
                              <CuiNumberFormat
                                value={getSubTotalAmount(
                                  [taskType],
                                  invoice.billingInfo.chargeAmendmentsFrom,
                                  invoice.billingInfo.amendmentRate
                                )}
                                displayType={'text'}
                                addPrefix
                              />
                            </Stack>
                          </CuiAccordionDetails>
                        </CuiAccordion>
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{my: 2}} />
                    </Grid>
                    <Grid item xs={12}>
                      {(projectBilling || invoice) && (
                        <InvoiceDetailsTotal
                          invoice={invoice}
                          billingInfo={
                            projectBilling?.billingInfo || invoice?.billingInfo
                          }
                          invoicesAmount={invoices.length}
                        />
                      )}
                    </Grid>
                  </CuiAccordionDetails>
                </CuiAccordion>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {projectBilling && onChangeBillingInfo && (
                <InvoicesDetailsFooter
                  projectBilling={projectBilling}
                  onChangeBillingInfo={onChangeBillingInfo}
                  disabled={isSaving}
                  invoices={invoices}
                />
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {projectBilling && (
          <CuiCancelSaveButton
            onCancel={onClose}
            onSave={onSaveClick}
            disableSave={isSaving}
            disableCancel={isSaving}
            error={isSavingFailed}
            loading={isSaving}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}
